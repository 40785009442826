@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colors */
  --color-primary: #4361ee;
  --color-accent: #60a5fa;
  
  /* Transitions */
  --transition-fast: 300ms;
  --transition-medium: 500ms;
  --transition-slow: 800ms;
  --ease-smooth: cubic-bezier(0.16, 1, 0.3, 1);
  
  /* Effects */
  --blur-sm: 8px;
  --blur-md: 12px;
  --blur-lg: 16px;
  --blur-xl: 24px;
  
  /* Shadows */
  --shadow-glow: 0 0 var(--blur-lg) rgba(67, 97, 238, 0.15);
  --shadow-glow-accent: 0 0 var(--blur-lg) rgba(96, 165, 250, 0.15);
}

@layer base {
  html {
    scroll-behavior: smooth;
    @apply antialiased;
    font-family: 'Nunito Sans', system-ui, sans-serif;
  }

  body {
    font-family: 'Nunito Sans', system-ui, sans-serif;
    @apply bg-gradient-to-br from-white via-gray-50 to-gray-100
           dark:from-[#0a0c10] dark:via-[#111318] dark:to-[#0a0c10]
           text-gray-900 dark:text-gray-100 
           min-h-screen overflow-x-hidden;
  }

  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Sans', system-ui, sans-serif;
    @apply font-bold tracking-tight;
  }

  h1 { @apply text-4xl md:text-5xl xl:text-6xl leading-tight; }
  h2 { @apply text-3xl md:text-4xl xl:text-5xl leading-tight; }
  h3 { @apply text-2xl md:text-3xl xl:text-4xl leading-snug; }
  h4 { @apply text-xl md:text-2xl xl:text-3xl leading-snug; }

  /* Focus styles */
  :focus-visible {
    @apply outline-none ring-2 ring-primary/50 dark:ring-accent/50 
           ring-offset-2 ring-offset-white dark:ring-offset-gray-900 
           rounded-xl transition-shadow duration-300;
  }
}

@layer components {
  /* Glass Effects */
  .glass {
    @apply bg-white/90 dark:bg-gray-900/90 backdrop-blur-lg 
           border border-white/20 dark:border-gray-700/30
           shadow-lg shadow-gray-200/20 dark:shadow-black/10
           transition-all duration-500;
  }

  .glass-card {
    @apply glass rounded-2xl shadow-xl;
  }

  /* Cards */
  .card-base {
    @apply rounded-2xl transition-all duration-500 overflow-hidden;
  }

  .card {
    @apply card-base bg-white dark:bg-gray-800
           shadow-lg hover:shadow-xl 
           border border-gray-100 dark:border-gray-700/50
           hover:border-primary/20 dark:hover:border-accent/20
           hover:-translate-y-1;
  }

  /* Buttons */
  .button-base {
    @apply rounded-xl transition-all duration-300
           font-semibold py-3 px-6
           disabled:opacity-50 disabled:cursor-not-allowed
           focus:outline-none focus:ring-2;
  }

  .button {
    @apply button-base relative overflow-hidden
           shadow-md hover:shadow-xl
           bg-gradient-to-r from-primary to-primary/90 
           dark:from-accent dark:to-accent/90
           hover:from-primary/90 hover:to-primary
           dark:hover:from-accent/90 dark:hover:to-accent
           text-white
           hover:-translate-y-0.5 active:translate-y-0
           focus:ring-primary/50 dark:focus:ring-accent/50;

    &::before {
      content: '';
      @apply absolute inset-0 bg-gradient-to-r from-white/0 via-white/20 to-white/0
             translate-x-[-200%] transition-transform duration-[1.5s];
    }

    &:hover::before {
      @apply translate-x-[200%];
    }
  }

  /* Gallery */
  .paper-gallery {
    @apply bg-transparent;

    .image-gallery-slide {
      @apply glass-card;
    }

    .image-gallery-thumbnail {
      @apply card-base border-2 border-transparent
             hover:shadow-lg;

      &.active, &:hover {
        @apply border-primary dark:border-accent 
               scale-[1.02] -translate-y-0.5;
      }

      img {
        @apply transition-transform duration-500 hover:scale-110;
      }
    }

    .image-gallery-description {
      @apply bg-black/60 backdrop-blur-md text-white 
             py-4 px-6 absolute bottom-0 w-full text-center
             border-t border-white/10;
    }

    .image-gallery-icon {
      @apply button-base bg-white/80 dark:bg-gray-800/80 
             backdrop-blur-sm p-3 rounded-full
             hover:bg-white dark:hover:bg-gray-800;

      &:hover {
        @apply text-primary dark:text-accent 
               shadow-lg scale-110;
      }
    }
  }
}

@layer utilities {
  /* Scrollbars */
  .custom-scrollbar {
    @apply scrollbar-thin scrollbar-track-transparent
           scrollbar-thumb-gray-400/50 hover:scrollbar-thumb-gray-500/50;
  }

  /* Effects */
  .transition-standard {
    transition: all var(--transition-medium) var(--ease-smooth);
  }

  .hover-lift {
    @apply hover:-translate-y-1.5 hover:shadow-xl transition-standard;
  }

  .hover-scale {
    @apply hover:scale-[1.02] transition-standard;
  }

  .blur-behind {
    @apply relative;
    
    &::before {
      content: '';
      @apply absolute inset-0 -z-10 opacity-40 transition-opacity duration-500;
      filter: blur(var(--blur-xl));
      background: linear-gradient(to right, 
        var(--color-primary-20) 0%,
        var(--color-accent-20) 50%,
        var(--color-primary-20) 100%
      );
    }
  }

  .gradient-text {
    @apply bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent;
  }

  .glow {
    box-shadow: var(--shadow-glow);
    @apply dark:shadow-accent;
  }

  .border-gradient {
    @apply relative rounded-2xl p-[1px];
    background: linear-gradient(to right, 
      var(--color-primary-50),
      var(--color-accent-50)
    );
    
    &::before {
      content: '';
      @apply absolute inset-0 rounded-2xl bg-white dark:bg-gray-800 -z-10;
    }
  }
}

/* Animations */
@keyframes shine {
  from { background-position: 200% center; }
  to { background-position: -200% center; }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: scale(0.98) translateY(1rem);
  }
  to { 
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn var(--transition-slow) var(--ease-smooth) forwards;
}

.animate-slide-up {
  animation: fadeIn var(--transition-slow) var(--ease-smooth) forwards;
  transform-origin: center bottom;
}